import React from "react";
import routes from "./routes";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const loading = (
  <div className="loader-component">
    <div class="spinner-border text-secondary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
);

function App() {
  return (
    <div className="App">
      <Router>
        <React.Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                  />
                )
              );
            })}
            <Redirect to="/" />
          </Switch>
        </React.Suspense>
      </Router>
    </div>
  );
}

export default App;
