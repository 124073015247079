import React from "react";
const Page404 = React.lazy(() => import("./components/page404"));
const TheLayout = React.lazy(() => import("./components/TheLayout"));

const routes = [
  { path: "/", exact: true, name: "404", component: Page404 },
  { path: "/:clientName", name: "Client Data", component: TheLayout },
];

export default routes;
